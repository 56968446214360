window.angular
    .module('hippo')
    .service('fullStoryService', function ($location, $rootScope, userDataService, featureToggle) {
        'ngInject';

        const { _ } = window;
        let injectedFullStoryScript = false;
        const leadIdToHasCalledIdentifyMap = {};
        const excludedPaths = ['/', '/product-select', '/your-address', '/how-property-is-used', '/call-us'];
        const utmSourcesToIncludeForLandingPage = ['google', 'esurance'];
        const builtInFullstoryFields = ['email', 'displayName'];

        this.getFullStoryDataType = (value) => {
            const valueType = typeof value;
            switch (valueType) {
                case 'string':
                    return 'str';
                case 'boolean':
                    return 'bool';
                case 'number':
                    return value % 1 === 0 ? 'int' : 'real';
                case 'object':
                    return value instanceof Date ? 'date' : valueType;
                default:
                    return valueType;
            }
        };

        // Fullstory custom user data must be in a specific format:
        // https://help.fullstory.com/develop-js/setuservars
        this.convertToValidFullStoryData = (data) => {
            return _.mapKeys(data, (value, key) => {
                const camelCaseKey = _.camelCase(key);
                if (_.includes(builtInFullstoryFields, key)) {
                    return camelCaseKey;
                } else {
                    return camelCaseKey + '_' + this.getFullStoryDataType(value);
                }
            });
        };

        const injectFullstoryScript = () => {
            const fullStoryKey = window.hippoConfig.LEAD_FULLSTORY_KEY;
            window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
            window['_fs_org'] = fullStoryKey;
            window['_fs_namespace'] = 'FS';

            if (!fullStoryKey || fullStoryKey === 'NoKey' || userDataService.isLocalHost()) {
                return;
            }

            // Check if we're in an iframe
            // https://help.fullstory.com/using/recording-iframes?from_search=19887713
            if (window !== window.top) {
                if (featureToggle.isProducerFlow()) {
                    window['_fs_run_in_iframe'] = true;
                } else {
                    window['_fs_is_outer_script'] = true;
                }
            }

            /*eslint-disable*/
            (function (m, n, e, t, l, o, g, y) {
                if (e in m) {
                    if (m.console && m.console.log) {
                        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                    }
                    return;
                }
                g = m[e] = function (a, b, s) {
                    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
                };
                g.q = [];
                o = n.createElement(t);
                o.async = 1;
                o.crossOrigin = 'anonymous';
                o.src = 'https://' + _fs_script;
                y = n.getElementsByTagName(t)[0];
                y.parentNode.insertBefore(o, y);
                g.identify = function (i, v, s) {
                    g(l, { uid: i }, s);
                    if (v) g(l, v, s);
                };
                g.setUserVars = function (v, s) {
                    g(l, v, s);
                };
                g.event = function (i, v, s) {
                    g('event', { n: i, p: v }, s);
                };
                g.anonymize = function () {
                    g.identify(!!0);
                };
                g.shutdown = function () {
                    g('rec', !1);
                };
                g.restart = function () {
                    g('rec', !0);
                };
                g.log = function (a, b) {
                    g('log', [a, b]);
                };
                g.consent = function (a) {
                    g('consent', !arguments.length || a);
                };
                g.identifyAccount = function (i, v) {
                    o = 'account';
                    v = v || {};
                    v.acctId = i;
                    g(o, v);
                };
                g.clearUserCookie = function () {};
                g.setVars = function (n, p) {
                    g('setVars', [n, p]);
                };
                g._w = {};
                y = 'XMLHttpRequest';
                g._w[y] = m[y];
                y = 'fetch';
                g._w[y] = m[y];
                if (m[y])
                    m[y] = function () {
                        return g._w[y].apply(this, arguments);
                    };
                g._v = '1.3.0';
            })(window, document, window['_fs_namespace'], 'script', 'user');
            /*eslint-enabled*/
        };

        /**
         * @params {object} data - optional. address or leadData (e.g. personal_information.address, property_data.address)
         **/
        this.identify = (leadId, data) => {
            if (!injectedFullStoryScript) {
                injectFullstoryScript();
            }

            if (!window.FS) {
                return;
            }

            if (leadIdToHasCalledIdentifyMap[leadId]) {
                window.FS.setUserVars(this.convertToValidFullStoryData(data));
            } else {
                window.FS.identify(leadId, this.convertToValidFullStoryData(data));
                leadIdToHasCalledIdentifyMap[leadId] = true;
            }
        };

        function inject() {
            if (!injectedFullStoryScript) {
                injectedFullStoryScript = true;
                injectFullstoryScript();
            }
        }

        this.inject = () => {
            inject();
        };

        $rootScope.$on('$locationChangeSuccess', (angularEvent, newUrl, oldUrl, newState, oldState) => {
            const currentPath = $location.path();
            const utmSource = $location.$$search.utm_source;

            if (
                excludedPaths.indexOf(currentPath) === -1 ||
                (currentPath === '/' && utmSourcesToIncludeForLandingPage.indexOf(utmSource) !== -1)
            ) {
                this.inject();
            }
        });
    });
